import { Employee } from "../employee-list";
import imgAnnaLenaHarth from ".././media/anna-lena-harth.png";

export const annaLenaHarth: Employee = {
  slug: "kanna-lena-harth",
  displayName: "Anna-Lena Harth",
  title: "personal-assistant-female",
  languages: ["deu", "eng"],
  isExternal: false,
  hasProfile: false,
  img: imgAnnaLenaHarth,
  translations: [],
};
