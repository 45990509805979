import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import { useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { companyColors } from "../../global/companyColors";
import { ContactForm } from "./contactForm";

export function ContactFormModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isSmall = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const { languageCode } = useParams();
  const { t } = useTranslation();
  return (
    <>
      {isSmall ? (
        <Link to={`/${languageCode}/contact/request`}>
          <Button colorScheme="blue">{t("contactForm-Button")}</Button>
        </Link>
      ) : (
        <>
          <Button onClick={onOpen} colorScheme="blue">
            {t("contactForm-Button")}
          </Button>
          <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent backgroundColor={companyColors.blue[400]}>
              {/*    <ModalHeader></ModalHeader>
          <ModalCloseButton /> */}
              <ModalBody pb={6}>
                <ContactForm />
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}
