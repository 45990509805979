import { HStack, StackDirection, VStack } from "@chakra-ui/layout";
import {
  Card,
  CardBody,
  Stack,
  Text,
  Image,
  useBreakpointValue,
  IconButton,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { RxDoubleArrowRight } from "react-icons/rx";
import { companyColors } from "../../global/companyColors";
import { Employee } from "./employee-list";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LinkButton } from "../../generic/LinkButton";
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

export function EmployeeCard({ employee }: { employee: Employee }) {
  const { t } = useTranslation();
  const spacing = useBreakpointValue(
    { base: undefined, md: 4 },
    { ssr: false }
  );
  const { languageCode } = useParams();
  const direction = useBreakpointValue<StackDirection>({
    base: "column",
    md: "row",
  });
  return (
    <SimpleGrid
      templateColumns={{ base: "1fr", md: "1fr 1fr" }}
      flexDirection="column"
      spacing={8}
      justifyContent="space-between"
      mb={4}
    >
      {employee.hasProfile != false ? (
        <HStack spacing={2}>
          <Link to={`/${languageCode}/team/${employee.slug}`}>
            <Image src={employee.img} w={220} minW={220} rounded={4} />
          </Link>
          {/*  <EmployeeAward employee={employee} /> */}
        </HStack>
      ) : (
        <HStack spacing={2}>
          <Image src={employee.img} w={220} rounded={4} />
          {/*  <EmployeeAward employee={employee} /> */}
        </HStack>
      )}
      <VStack
        flexWrap="wrap"
        alignItems="flex-start"
        w={{ base: "220px", md: "220px" }}
      >
        <Text fontSize="20px">
          {employee.displayName}
          {employee.displayNameSuffix && ", " + employee.displayNameSuffix}
        </Text>
        <Text>
          {t(employee.title)}
          {employee.isExternal && <span>, {t("of-counsel")}</span>}
          {employee.subTitle && <span>, {t(employee.subTitle)}</span>}
        </Text>

        {employee.hasProfile != false && (
          <LinkButton
            label={t("to-profile")}
            link={`/${languageCode}/team/${employee.slug}`}
          />
        )}
        {/*  <IconButton
              aria-label="Search database"
              icon={<RxDoubleArrowRight />}
            /> */}
      </VStack>
    </SimpleGrid>
  );
}

function EmployeeAward({ employee }: { employee: Employee }) {
  return (
    <>
      {employee.awards && employee.awards.length > 0 && (
        <HStack>
          {employee.awards.map((award) => (
            <Image h={184} src={award} w={100} rounded={4} />
          ))}
        </HStack>
      )}
    </>
  );
}
