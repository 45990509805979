import { useEffect, useRef, useState } from "react";
import { AnimatePresence, isValidMotionProp, motion } from "framer-motion";
import useMeasure from "react-use-measure";
import { Button, IconButton } from "@chakra-ui/button";
import {
  chakra,
  shouldForwardProp,
  Box,
  Container,
  useBreakpointValue,
  Text,
  HStack,
} from "@chakra-ui/react";
import { companyColors } from "../../../global/companyColors";
import { MaxWidthContainer } from "../../../generic/layout/maxWidthContainer";
import { defaultFontSize, maxWidth } from "../../../generic/chakraUiTheme";
import { RxDot, RxDotFilled } from "react-icons/rx";
import { CgArrowLeftR, CgArrowRightR } from "react-icons/cg";
import {
  convertStringToWebsiteLang,
  fallbackLng,
  TranslatedInfo,
  Translations,
  websiteLanguages,
} from "../../../generic/i18n";
import { useParams } from "react-router-dom";
import i18n from "../../../generic/i18n";

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export function Caroussel() {
  let [ref, { width, height }] = useMeasure();
  const { languageCode } = useParams();
  let [count, setCount] = useState(0);
  let prev = usePrevious(count);
  let direction = count > prev ? 1 : -1;

  const px = useBreakpointValue({ base: 4, md: 10 }, { ssr: false }) || 4;
  const carousselHeight =
    useBreakpointValue({ base: 270, md: 170 }, { ssr: false }) || 220;

  const pxInPixel =
    useBreakpointValue({ base: 16, md: 16 }, { ssr: false }) || 4;
  const w = useBreakpointValue(
    { base: "100%", lg: "100%", xl: width },
    { ssr: false }
  );

  const [carousselItems, setCarousselItems] = useState<[]>([]);

  useEffect(() => {
    let carousselItems: [] = i18n.t("website-content-landing-caroussel.items", {
      returnObjects: true,
      lng: languageCode,
    });
    setCarousselItems(carousselItems);
  }, [languageCode]);

  return (
    <Box display="flex" alignItems="center" flexDirection="column" w="100%">
      {/*   <Box>{width}</Box> */}

      <Box
        pos="relative"
        /*    display="flex" */
        display={"block"}
        h={carousselHeight}
        overflowX="hidden"
        ref={ref}
        /* w={w || maxWidth} */
        w="100%"
        bg={companyColors.blue[200]}
      >
        <AnimatePresence custom={{ direction, width }}>
          <ChakraBox
            key={count.toString() + languageCode || ""}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            pos={"absolute"}
            display="flex"
            h={height - 20}
            w="100%"
            justifyItems="center"
            alignItems={"flex-start"}
            custom={{ direction, width }}
          >
            <Text fontSize={{ base: defaultFontSize, md: 20 }} w={width}>
              {carousselItems[Math.abs(count) % carousselItems.length]}
            </Text>
          </ChakraBox>
        </AnimatePresence>
      </Box>
      <HStack mt={1}>
        <IconButton
          aria-label="Prev"
          icon={<CgArrowLeftR size={24} color={companyColors.blue[800]} />}
          onClick={() => setCount(count - 1)}
          variant="ghost"
        />

        {carousselItems.map((item, i) => (
          <span>
            {i == Math.abs(count) % carousselItems.length ? (
              <RxDotFilled />
            ) : (
              <RxDot />
            )}
          </span>
        ))}

        <IconButton
          aria-label="Next"
          icon={<CgArrowRightR size={24} color={companyColors.blue[800]} />}
          onClick={() => setCount(count + 1)}
          variant="ghost"
        />
      </HStack>
    </Box>
  );
}

let variants = {
  enter: ({ direction, width }: { direction: number; width: number }) => ({
    x: direction * width,
    transition: {
      ease: "easeInOut",
      duration: 0.6,
    },
  }),
  center: {
    x: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.6,
    },
  },
  exit: ({ direction, width }: { direction: number; width: number }) => ({
    x: direction * -width,
    transition: {
      ease: "easeInOut",
      duration: 0.6,
    },
  }),
};

function usePrevious(state: any) {
  let [tuple, setTuple] = useState([null, state]);

  if (tuple[1] !== state) {
    setTuple([tuple[1], state]);
  }

  return tuple[0];
}
