import {
  VStack,
  Box,
  useBreakpointValue,
  HStack,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { useParams } from "react-router-dom";
import { Heading } from "../../generic/layout/heading";
import { useTranslation } from "react-i18next";
import { companyColors } from "../../global/companyColors";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { useScroll, useSpring } from "framer-motion";
import { ServicesContent } from "../services/ServicesContent";
import { EmployeeList } from "../team/EmployeeList";
import { ContactContent } from "../contact/content";
import { Caroussel } from "./caroussel/caroussel";
import bestLawyers from "../team/media/HB_Dtld_Beste_Anwaelte2024_Dr_Wajma_Mangal.png";
import { maxWidth } from "../../generic/chakraUiTheme";

export function LandingPage() {
  const { languageCode } = useParams();
  const { t } = useTranslation();

  const bg =
    useBreakpointValue(
      {
        base: "/media/bg_landing_mobile.jpg",
        md: "/media/bg_landing.jpg",
      },
      { ssr: false }
    ) || "/media/bg_landing.jpg";

  return (
    <VStack spacing={0} w={"100%"}>
      <BannerWithHeading bgUrl={bg} headingText={t("landing.claim")} />

      <SimpleGrid
        templateColumns={{ base: "100px 1fr", md: "133px 1fr" }}
        flexDirection="column"
        spacing={{ base: 4, md: 6 }}
        justifyContent="space-between"
        bg={companyColors.blue[200]}
        w={"100%"}
        maxWidth={maxWidth}
        p={{ base: 4, md: 10 }}
        px={{ base: 4, md: 10 }}
      >
        <Image src={bestLawyers} rounded={4} w={{ base: 100, md: 133 }} />

        <Caroussel />
      </SimpleGrid>

      <Box h={{ base: 0, md: 50 }} />
      <Box>
        <ServicesContent showOnStartPage={true} />
        <Box h={{ base: 0, md: 26 }} />
        <EmployeeList />
      </Box>

      <Box bg={companyColors.blue[200]} pt={10} w="100%">
        <MaxWidthContainer>
          <Heading>{t("contact")}</Heading>
          <ContactContent />
          {/*  <Divider borderColor={companyColors.blue[800]} /> */}
        </MaxWidthContainer>
      </Box>
    </VStack>
  );
}
