import { Box, SimpleGrid, VStack } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { servicesList } from "./services-list";
import { ServiceCard } from "./ServiceCard";
import React from "react";

export function ServicesContent({
  showOnStartPage,
}: {
  showOnStartPage?: boolean | undefined;
}) {
  const { t } = useTranslation(["translation", "contentServices"]);
  const { languageCode } = useParams();
  return (
    <VStack spacing={0}>
      <BannerWithHeading bgUrl="/media/bg.jpg" headingText={t("services")} />

      <Box py={8}>
        <MaxWidthContainer>
          <Text fontSize={{ base: 18, md: 20 }}>
            <Trans i18nKey={"intro"} ns="contentServices"></Trans>
          </Text>
          <Box h={10} />
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacingX="40px"
            spacingY="20px"
          >
            {servicesList.map((service) => (
              <ServiceCard
                key={service.service}
                service={service.service}
                color={service.color}
              />
            ))}
          </SimpleGrid>
        </MaxWidthContainer>
      </Box>
    </VStack>
  );
}
