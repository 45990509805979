import { Box, Divider, SimpleGrid, VStack } from "@chakra-ui/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { Heading } from "../../generic/layout/heading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { companyColors } from "../../global/companyColors";
import { employeeList } from "./employee-list";
import { EmployeeCard } from "./EmployeeCard";
import { HStack, useBreakpointValue } from "@chakra-ui/react";
import useMeasure from "react-use-measure";

export function EmployeeList() {
  const { t } = useTranslation();
  const { languageCode } = useParams();
  let [ref, { width, height }] = useMeasure();
  const breakPoint = useBreakpointValue({
    base: "base",
    xs: "xs",
    s: "s",
    md: "md",
    lg: "lg",
    xl: "xl",
  });
  return (
    <VStack spacing={0} ref={ref}>
      <BannerWithHeading bgUrl="/media/bg2.jpg" headingText={t("team")} />

      <MaxWidthContainer py={8} w="100%">
        <VStack spacing={8} justifyItems={"center"}>
          {/*       <Box>
            breakPoint: {breakPoint} / width: {width}
          </Box> */}
          <EmployeeCard employee={employeeList[0]} />
          <Divider borderColor={companyColors.blue[400]} />
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacingX="40px"
            spacingY="20px"
            w="100%"
            justifyItems={"center"}
          >
            <HStack>
              <EmployeeCard employee={employeeList[1]} />
              <Divider
                borderColor={companyColors.blue[400]}
                orientation="vertical"
                display={{ base: "none", lg: "block" }}
              />
            </HStack>
            <Divider
              borderColor={companyColors.blue[400]}
              orientation="horizontal"
              display={{ base: "block", lg: "none" }}
            />
            <EmployeeCard employee={employeeList[2]} />
          </SimpleGrid>
          <Divider borderColor={companyColors.blue[400]} />
          <EmployeeCard employee={employeeList[3]} />
          <Divider borderColor={companyColors.blue[400]} />
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacingX="40px"
            spacingY="20px"
            w="100%"
            justifyItems={"center"}
          >
            <HStack>
              <EmployeeCard employee={employeeList[4]} />
              <Divider
                borderColor={companyColors.blue[400]}
                orientation="vertical"
                display={{ base: "none", lg: "block" }}
              />
            </HStack>
            <Divider
              borderColor={companyColors.blue[400]}
              orientation="horizontal"
              display={{ base: "block", lg: "none" }}
            />
            <EmployeeCard employee={employeeList[5]} />
          </SimpleGrid>
          {/*           {employeeList.map((employee, i) => (
            <React.Fragment key={employee.slug}>
              <EmployeeCard employee={employee} />
              {i + 1 < employeeList.length && (
                <Divider borderColor={companyColors.blue[400]} />
              )}
            </React.Fragment>
          ))} */}
        </VStack>
      </MaxWidthContainer>
    </VStack>
  );
}
